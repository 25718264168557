import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: '首页',name:"keywords", content:"捷码云,捷码云SaaS,摄影小程序,研学小程序,APP软件定制开发,南京捷码云信息科技有限公司"},
    component: HomeView
   
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { title: '关于我们',name:"keywords", content:"捷码云,捷码云SaaS,摄影小程序,研学小程序,APP软件定制开发,南京捷码云信息科技有限公司" }
  },
  {
    path: '/technical',
    name: 'technical',
    component: () => import(/* webpackChunkName: "about" */ '../views/TechnicalView.vue'),
    meta: { title: '技术支持' }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue'),
    meta: { title: '新闻中心' }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductView.vue'),
    meta: { title: '产品中心' }
  },
  {
    path: '/productCate',
    name: 'productCate',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/productCateView.vue'),
    meta: { title: '产品中心' }
  },
  {
    path: '/productCates',
    name: 'productCates',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/productCateViews.vue'),
    meta: { title: '产品中心' }
  },
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiceView.vue'),
    meta: { title: '定制服务' }
  },
  {
    path: '/serviceInfo',
    name: 'serviceInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/service/index.vue'),
    meta: { title: '服务中心' }
  },
  {
    path: '/serviceInfo',
    name: 'serviceInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/service/index.vue'),
    meta: { title: '服务中心' }
  },
  {
    path: '/productInfo',
    name: 'productInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/index.vue'),
    meta: { title: '产品中心' }
  },
  {
    path: '/technicalInfo',
    name: 'technicalInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/technical/index.vue'),
    meta: { title: '技术支持' }
  },
]

const router = new VueRouter({
  routes
})
// 全局导航守卫  
router.beforeEach((to, from, next) => {  
  document.title = to.meta.title || '三京官网';  
  next();  
});  

export default router
