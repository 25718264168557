<template>
  <div id="app">
    <!-- <div class="flex_center nav_bar">
      <div class="flex_between nav_bars">
        <img
          class="logo_img"
          v-if="shopList"
          :src="shopList.fullLogoUrl"
          width="120px"
          @click="goIndex"
        />
        <nav class="content_navs">
          <div class="flex-end content_">
            <router-link to="/">首页</router-link>
            <a class="relative hover-target">
              <div class="proudt">产品中心</div>
              <div class="tooltip flex">
                <div
                  class="tooltips"
                  v-for="(item, index) in categoryOptions"
                  :key="index"
                >
                  <div class="" @click="navigator(item)">
                    <div class="tooltip_name color">
                      {{ item.name }}
                    </div>
                    <div v-if="item.children">
                      <div v-for="(ite, ind) in item.children" :key="ind">
                        <div
                          class="tooltip_name color-weight"
                          @click.stop="navigator(ite)"
                        >
                          {{ ite.name }}
                        </div>
                        <div v-if="ite.children">
                          <div v-for="(it, id) in ite.children" :key="id">
                            <div class="tooltip_name" @click.stop="navigator(it)">
                              {{ it.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <router-link to="/service">定制服务</router-link>
            <router-link to="/technical">技术支持</router-link>
            <router-link to="/news">新闻中心</router-link>
            <router-link to="/about">关于三京</router-link>
          </div>
        </nav>
      </div>
    </div> -->
    <router-view />
    <div class="body_btn_foot">
      <div  class="body_btn_f">@ 2024 版权所有南京捷码云信息科技有限公司</div>
      <div class="body_btn_foots">
        网站备案号：
        <a
          class="btn_link"
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >苏ICP备2023027304号</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import logo from "@/assets/imgs/logo.png";
import { listProductCategory } from "@/api/activity";
import { getCompany } from "@/api/customer/outCustomer";
export default {
  metaInfo: {
    title: '捷码云科技-专业saas服务平台',
    meta: [
      { vmid: 'description', name: 'description', content: '捷码云科技-专业saas服务平台!致力于为更多企业提供敏捷、高效服务，精准赋能企业的数字化转型！' },
      { vmid: 'keywords', name:"keywords", content:"捷码云,捷码云SaaS,摄影小程序,研学小程序,APP软件定制开发,南京捷码云信息科技有限公司" }
    ]
  },
  data() {
    return {
      // title: defaultSettings.title,
      logo,
      title: "三京生物管理后台",
      isRouter: false,
      categoryOptions: [],
      shopList: "",
    };
  },
  created() {
    const flag = this.IsPC();
    console.log("三京生物管理后台", flag);

    this.initCategoryData();
    this.getShopInfo();
  },
  methods: {
   
    /**
     * 判断是否是pc设备
     */
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (window.screen.width >= 768) {
        flag = true;
      }
      return flag;
    },
    // 返回首页
    goIndex() {
      this.$router.push("/");
    },
    // 获取商家信息
    async getShopInfo() {
      const res = await getCompany();
      this.shopList = res.result;
      console.log("商家信息", this.shopList);
    },
    initCategoryData() {
      // 查询类目接口
      listProductCategory()
        .then((res) => {
          console.log("查询类目", res);
          if (res.code === "200") {
            if (res.result.list && res.result.list.length > 0) {
              var arr = res.result.list;
              this.categoryOptions = arr;
              // this.query.categoryId = arr[0].categoryId;
              // this.fatchData();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    navigator(item) {
      console.log("item", item);
      this.isRouter = !this.isRouter;
      if (this.isRouter) {
        this.$router.replace({
          name: "productCate",
          query: { productId: item },
        });
      } else {
        this.$router.replace({
          name: "productCates",
          query: { productId: item },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}
.rounded {
  cursor: pointer;
}
.tooltip {
  display: none;
  // right: 30px;
  // top: 30px;
  position: absolute;
  // right: 30px;
  // top: 80px;
  background-color: #f7fbff;
  color: #000;
  padding: 10px 5px;
  border-radius: 5px;
  z-index: 10;
  .tooltip_name {
    margin: 10px;
    font-size: 14px;
    cursor: pointer;
  }
  .tooltip_name:hover {
    margin: 10px;
    font-size: 14px;
    color: #3558ff;
    cursor: pointer;
  }
}
.tooltips {
  width: 200px;
  text-align: left;
}
.color {
  font-size: 16px;
  color: #3558ff;
}
.color-weight {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}
.hover-target:hover .tooltip {
  display: block;
  display: flex;
  position: absolute;
  background-color: #f7fbff;
  color: #000;
  // right: 30px;
  // top: 80px;
  padding: 10px 5px;
  border-radius: 5px;
  z-index: 10;
}
.content_navs {
  width: 70%;
  // margin: 0;
  .content_ {
    // width: 50%;
    // margin-left: 200px;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.logo_img {
  cursor: pointer;
}
nav {
  width: 30%;
  // padding: 30px;

  a {
    width: 15%;
    font-weight: bold;
    color: #020202;
    // margin: 0 10px;
    text-decoration: none;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    font-style: normal;
    &.router-link-exact-active {
      color: #3558ff;
      // padding-bottom: 20px;
      // text-decoration: none;
      text-decoration: dashed;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .content_navs {
    width: calc(100% - 120px);
    // margin: 0;
    .content_ {
      width: 100%;
      // margin-left: 20px;
    }
  }
}
</style>



